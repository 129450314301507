import * as React from 'react';
import { graphql } from 'gatsby';

// components
import RippleCanvas from '@src/components/rippleCanvas/RippleCanvas';
import Seo from '@src/components/seo/SEO';

// utils
import { renderSwitch } from '@src/utils/Render';

export const query = graphql`
    {
        page: craftLandingPageLandingPageEntry {
            ...LandingPageFragment
        }
    }
`;

const IndexPage = ({ data: { page }, location }) => {
    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }
    const content = page.contentMatrix;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
                landing
            />
            <div>
                <div className="m-240">
                    {page.hero[0] ? <RippleCanvas hero={page.hero[0]} /> : null}
                </div>
                {content.map((block, i) => renderSwitch(block, i))}
            </div>
        </>
    );
};

export default IndexPage;
