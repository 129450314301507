import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function CopyAnimation() {
    const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

    return (
        <h1 ref={ref} className="h1 display">
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                P
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                l
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                a
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                i
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                n
            </span>{' '}
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                R
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                a
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                i
            </span>
            <span className={`fade-in ${inView ? 'fade-in--active fade-in--delay-random' : ''}`}>
                n
            </span>
        </h1>
    );
}
