/* eslint-disable */

import React from 'react';
import _ from 'lodash';
import { isBrowser } from '@src/utils/SSR'

// components
import CopyAnimation from '@src/components/copyAnimation/CopyAnimation';
import LinkButton from '@src/components/button/LinkButton';
import Loader from '@src/components/loader/Loader';
import PinkCloud from '@src/components/cloud/PinkCloud';

// styles
import './rippleCanvas.scss';

// images
import defaultImage from '../../images/bottle.png';

class RippleCanvas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            windowWidth: 1280,
        };

        this.imageRef = React.createRef();

        this.canvasRef = React.createRef();
        this.ctx = null;

        this.width = 200;
        this.height = 200;

        this.riprad = 3;
        this.ripplemap = [];
        this.last_map = [];

        this.ripple = null;
        this.texture = null;

        this.intensity = 200;
        this.delay = 8;
    }

    componentDidMount() {
        this.updateDimensions();
        if (this.imageRef.current?.complete && !this.state.loaded) this.imageLoaded();
        
        window.addEventListener('resize', _.debounce(this.updateDimensions.bind(this), 50));
    }

    updateSettings() {
        this.half_width = this.width >> 1;
        this.half_height = this.height >> 1;
        this.size = this.width * (this.height + 2) * 2;
        this.oldind = this.width;
        this.newind = this.width * (this.height + 3);

        this.canvasRef.current.width = this.width;
        this.canvasRef.current.height = this.height;
    }

    imageLoaded() {
        this.setState({loaded: true});

        this.width = this.imageRef.current.offsetWidth;
        this.height = this.imageRef.current.offsetHeight;

        this.updateSettings();

        if (!this.canvasRef.current) return;
        this.ctx = this.canvasRef.current.getContext('2d');

        const imageObj = new Image();
        imageObj.src =
            this.props.asset?.imageFile?.childImageSharp.gatsbyImageData.images.fallback.src ||
            defaultImage;
        imageObj.onload = () => {
            this.ctx.drawImage(imageObj, 0, 0, this.width, this.height);
            this.updateCanvas();
        };

        // generate random ripples
        setInterval(this.ambient.bind(this), 1200);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.windowWidth !== prevState.windowWidth && this.state.loaded) {
            this.setState({loaded: false});
            clearInterval(this.interval);

            if (!this.ctx) {
                if (!this.canvasRef.current) return;
                this.ctx = this.canvasRef.current.getContext('2d');
            }

            this.width = this.imageRef.current.offsetWidth;
            this.height = this.imageRef.current.offsetHeight;

            this.updateSettings();

            this.ctx.clearRect(0, 0, this.width, this.height);
            this.ctx.fillStyle = 'rgba(68, 0, 121, 1)';
            this.ctx.fillRect = (0, 0, this.width, this.height);

            const imageObj = new Image();
            imageObj.src =
                this.props.asset?.imageFile?.childImageSharp.gatsbyImageData.images.fallback.src ||
                defaultImage;
            imageObj.onload = () => {
                this.ctx.drawImage(imageObj, 0, 0, this.width, this.height);
                this.updateCanvas();
                this.setState({ loaded: true });
            };
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: isBrowser() ? window.innerWidth : 1280,
        });
    }

    updateCanvas() {
        this.texture = this.ctx.getImageData(0, 0, this.width, this.height);
        this.ripple = this.ctx.getImageData(0, 0, this.width, this.height);

        for (let i = 0; i < this.size; i++) {
            this.last_map[i] = this.ripplemap[i] = 0;
        }

        this.interval = setInterval(this.run.bind(this), this.delay);
    }

    getRelativeCoordinates(e, el) {
        const position = {
            x: e.pageX,
            y: e.pageY,
        };

        const offset = {
            left: el.offsetLeft,
            top: el.offsetTop,
        };

        let reference = el.offsetParent;

        while (reference) {
            offset.left += reference.offsetLeft;
            offset.top += reference.offsetTop;
            reference = reference.offsetParent;
        }

        return {
            x: position.x - offset.left,
            y: position.y - offset.top,
        };
    }

    disturb(e) {
        // console.log('--- d i s t u r b ---');
        const { x, y } = this.getRelativeCoordinates(e, this.canvasRef.current);
        let dx = x;
        let dy = y;
        // let dy = e.pageY - this.canvasRef.current.offsetTop;

        dx <<= 0;
        dy <<= 0;

        for (let j = dy - this.riprad; j < dy + this.riprad; j++) {
            for (let k = dx - this.riprad; k < dx + this.riprad; k++) {
                this.ripplemap[this.oldind + j * this.width + k] += this.intensity;
            }
        }
    }

    ambient() {
        // console.log('--- a m b i e n t ---');
        const rnd = Math.random;

        let dx = rnd() * this.width;
        let dy = rnd() * this.height;

        dx <<= 0;
        dy <<= 0;

        for (let j = dy - this.riprad; j < dy + this.riprad; j++) {
            for (let k = dx - this.riprad; k < dx + this.riprad; k++) {
                this.ripplemap[this.oldind + j * this.width + k] += this.intensity;
            }
        }
    }

    run() {
        // console.log('--- run ---');
        this.newFrame();
        this.ctx.putImageData(this.ripple, 0, 0);
    }

    // branched from https://jsfiddle.net/esteewhy/5Ht3b/6/
    newFrame() {
        // console.log('--- newFrame ---');
        let a;
        let b;
        let data;
        let cur_pixel;
        let new_pixel;
        let old_data;

        const t = this.oldind;
        this.oldind = this.newind;
        this.newind = t;
        let i = 0;

        const _width = this.width;
        const _height = this.height;
        const _ripplemap = this.ripplemap;
        const _last_map = this.last_map;
        const _rd = this.ripple.data;
        const _td = this.texture.data;
        const _half_width = this.half_width;
        const _half_height = this.half_height;

        for (let y = 0; y < _height; y++) {
            for (let x = 0; x < _width; x++) {
                const _newind = this.newind + i;
                const _mapind = this.oldind + i;

                data =
                    (_ripplemap[_mapind - _width] +
                        _ripplemap[_mapind + _width] +
                        _ripplemap[_mapind - 1] +
                        _ripplemap[_mapind + 1]) >>
                    1;

                data -= _ripplemap[_newind];
                data -= data >> 5;

                _ripplemap[_newind] = data;

                // where data = 0 then still, where data > 0 then wave
                data = 1024 - data;

                old_data = _last_map[i];
                _last_map[i] = data;

                if (old_data !== data) {
                    // offsets
                    a = ((((x - _half_width) * data) / 1024) << 0) + _half_width;
                    b = ((((y - _half_height) * data) / 1024) << 0) + _half_height;

                    // bounds check
                    if (a >= _width) a = _width - 1;
                    if (a < 0) a = 0;
                    if (b >= _height) b = _height - 1;
                    if (b < 0) b = 0;

                    new_pixel = (a + b * _width) * 4;
                    cur_pixel = i * 4;

                    _rd[cur_pixel] = _td[new_pixel];
                    _rd[cur_pixel + 1] = _td[new_pixel + 1];
                    _rd[cur_pixel + 2] = _td[new_pixel + 2];
                }

                ++i;
            }
        }
    }

    render() {
        const stableImage = this.props.asset?.imageFile && this.props.asset?.imageFile.childImageSharp ? this.props.asset?.imageFile.childImageSharp.gatsbyImageData.images.fallback.src : defaultImage;

        return (
            <div className={`ripple-wrapper relative flex wrap`}>
                {this.props.hero ? (
                    <div className="ripple-wrapper__clouds relative full fade-in fade-in--delay">
                        <PinkCloud />
                    </div>
                ) : null}

                <div
                    className={`ripple-wrapper__image-wrapper fade-in fade-in--active flex relative justify-center full ${
                        this.props.product ? '' : 'ripple-wrapper__image-wrapper--hero flip m-42'
                    }`}>
                    <canvas
                        ref={this.canvasRef}
                        className={`canvas `}
                        style={{
                            visibility: this.state.loaded ? 'visible' : 'hidden',
                            maxHeight: this.props.short ? '45vh' : '',
                        }}
                        onMouseMove={this.disturb.bind(this)}
                    />

                    <img
                        ref={this.imageRef}
                        alt=""
                        className={`ripple-wrapper__image ${
                            this.props.product ? '' : 'ripple-wrapper__image--hero'
                        }`}
                        src={stableImage}
                        style={{
                            visibility: this.state.loaded ? 'visible' : 'hidden',
                            maxHeight: this.props.short ? '45vh' : '',
                        }}
                        onLoad={() => this.imageLoaded()}
                    />

                    {this.state.loaded ? null : <Loader />}
                </div>

                {this.props.hero ? (
                    <>
                        <div className="fade-in fade-in--active fade-in--delay full center-text o-0">
                            {/* <h1 className="h1 display" dangerouslySetInnerHTML={{ __html: hero }} /> */}
                            <CopyAnimation />
                        </div>

                        <div className="relative flex full justify-center">
                            <LinkButton large to="/shop/all" copy="shop" />
                        </div>
                    </>
                ) : null}
            </div>
        );
    }
}

export default RippleCanvas;
